
const ExtId = 'fastx@starnet.com'

export class KeyStore {
  constructor() {

  }
  read() {
    return new Promise((resolve, reject) => {
      let el = document.getElementById(ExtId);
      if(!el)
        return reject(new Error('Missing Extension Element'));
      const name = 'request-identities';
      el.addEventListener(name + '-ready', (evt:CustomEvent) => {
        let det = JSON.parse(evt.detail);
        console.log('Identities from agent', det.map(c => c.comment))
        resolve(det);
      }, { once: true});
      el.dispatchEvent(new CustomEvent(name));
      setTimeout(() => {
        reject(new Error('Event time out'));
      }, 30000);
    })
  }
}
