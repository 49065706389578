<div class="w-100">
    <error-alert [error]="error"></error-alert>
    <form [formGroup]="form" (keydown.enter)="onSubmit ()">
        <div class="">
            <div class="mb-3">
                <div class="">
                   
                    <div class="input-group">
                        <span class="input-group-text icon-prefix">
                            <fa-icon icon="user-tie"></fa-icon>
                        </span>
                        <div class="form-floating">
                            <input type="text"  
                            class="form-control " 
                            required='required'  
                            form="form" 
                            formControlName="username"
                            placeholder="admin"
                            id="userInput"
                            #username 
                            value="admin"
                        />
                        <label for="userInput">Username</label>
                        </div>
                        
                    </div>
                   
                </div>
                
            </div>
            <div class="mb-1" id="password-input">
                <div class="">
                   
                    <div class="input-group input-group">
                        <span class="input-group-text icon-prefix">
                            <fa-icon icon="key"></fa-icon>
                        </span>
                        <div class="form-floating">
                            <input [type]="inputType()" 
                            form="form"  
                            formControlName="password" 
                            id="answer"
                            placeholder="**********" 
                            class="form-control " />
                            
                            <label  class="mb-0" for="answer">Password</label>
                        </div>
                        <button (click)="toggleInputType()" class='btn btn-link border'>
                            <fa-icon icon="eye" ></fa-icon>
                        </button>
                        
                        
                    </div>
                   
                </div>
                    
    
            </div>
                        
            <div class="mb-4">
                <div class="mt-5 d-grid " role="group" aria-label="Login">
                    <button  class="btn btn-primary"

                        form="form" 
                            (click)="onSubmit ()">Admin Login</button>
                </div>
            </div>
        </div>
       
    </form>
</div>