import  { KEYUTIL, utf8tohex } from "jsrsasign";
import RSA from "./rsa";

import { PrivateKey as PKInterface } from "./interface";
import { AgentKey } from "./agent";
enum TYPES {
    RSA,
    ED25519
};

export  default class PrivateKey {
    constructor () {

    }
    static factory(str, agent?): PKInterface {
        if(agent) {
            return new AgentKey(str);
        }
        let lines = str.split('\n');
        if(lines[0].search(/-----BEGIN RSA PRIVATE KEY-----/) >=0){
            return new RSA(str);
        }else if(lines[0].search(/-----BEGIN PRIVATE KEY-----/) >= 0){
            return new RSA(str);
        }else if(lines[0].search(/-----BEGIN ENCRYPTED PRIVATE KEY-----/) >= 0){
            return new RSA(str);
        //} else if(lines[0].search(/-----BEGIN OPENSSH PRIVATE KEY-----/) >= 0) {
          //  return new Ed25519(str);
        } else {
            throw new Error('Private key format not supported'); 
        }
    }
}
