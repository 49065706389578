import { PrivateKey } from "./interface";

export class AgentKey implements PrivateKey {
        private pubKey: string;
        constructor(pubKey) {
            this.pubKey = pubKey;
        }
        isEncrypted() {
            return false;
        }
        decrypt(pass?: string) {
          return this.pubKey;
        }
        async sign(base64?:string) {
            const ExtId = 'fastx@starnet.com'
            return new Promise((resolve, reject) => {
                let el = document.getElementById(ExtId);
                if(!el)
                  return reject(new Error('Missing Extension Element'));
                const name = 'sign-request';
                el.addEventListener(name + '-ready', (evt:CustomEvent) => {
                  resolve(JSON.parse(evt.detail));
                }, { once: true});
                el.dispatchEvent(new CustomEvent(name, { detail: { 
                  blob: this.pubKey,
                  data: base64 
                }}));
                setTimeout(() => {
                  reject(new Error('Event time out'));
                }, 30000);
              });
        }
        verify(msg?:string, b64sig?:string) {

        }
        getPublicKeyBlob() {
            return this.pubKey;
        }   
    }