import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FxJsonEditorComponent } from './json-editor/json-editor.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  
  ],
  //directives:[ContainerDirective],
  declarations: [FxJsonEditorComponent],
  exports: [FxJsonEditorComponent],
})
export class FxJsonEditorModule { }
