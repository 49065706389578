import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { ConfigService } from "@Common/config";
import { WindowRef } from '@Common/browser';
import { ExternalUrlService } from '@Common/util/external-url.service';
import { Ssh } from "@Common/api/auth/ssh";
import { ConfigService as SSHConfig,  } from "@Common/ssh/ssh"
import { FormDataService } from "@Common/ssh/ssh"
import { loadTheme } from '@Common/page/ui/util';
import  KeyStore  from "@Common/ssh/key-store";
import { SetupService } from '@Common/page';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Login';
  loaded;
  config$;
  cf = {} as any;
  error;
  init;
  hideOtherFields = false;
  _adminLogin;
  showBanner;
  extensionLoaded;
  queryParams = {} as any;
  constructor (private cs: ConfigService, 
    private wref: WindowRef,
    private externalUrl: ExternalUrlService,
    private sshConfig: SSHConfig,
    private fds: FormDataService,
    private ss: SetupService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    
) {}
  ngOnInit () {
   try {
    this.wref.sessionStorage.removeItem ('autoconnect');
   } catch(e) {
      console.log(e)
   }
   this.ss.load();
   this.fds.patchValue({
     httpOnly: true
   });
    Ssh.api.setRootPath ('../..');
    this.config$ =  this.cs.subscribe (d => { 
      this.loaded = true; 
      this.changeBody (d);
      this.sshConfig.next (d);
      this.fds.patchValue({
        banner: d.banner
      }); 
      this.cf = d;
    })
    this.cs.loadUrl ('config');
    loadTheme ('../../theme/theme.css');
    this.route.queryParams.subscribe(d => {
      this.queryParams = d;
      this._adminLogin = Boolean(this.queryParams.admin);
      this.cdr.detectChanges();
    })
    let ks = new KeyStore ();
    this.wref.addEventListener('message', (evt) => {
      if(evt.data.msg !== 'fastx@starnet.com-ready')
        return;
      console.log('FastX extensions loaded:', ...evt.data.body.extensions);
      this.showBanner = false;
      this.extensionLoaded = true;
      this.cdr.detectChanges();
    });
    this.wref.postMessage({
      msg: 'fastx@starnet.com',
      body: {
        'ssh-agent': true
      }
    }, '*');
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if(!this.extensionLoaded)
        this.showBanner = true;
      this.cdr.detectChanges();
    }, 500)
   
  }
  cookies() {
    return navigator.cookieEnabled;
  }
  exec () {
    return Ssh.ssh;
  }
  ngOnDestroy () {
    this.config$.unsubscribe ();
  }
  changeBody (d) {
    if (d.hideBackground) return;
    this.wref.document.body.classList.add ('login-body');

  }
  onSuccess ($event) {
    return this.externalUrl.goToUrl ('../');
  }
  onCancel ($event) {
    this.hideOtherFields = false;
    return this.externalUrl.goToUrl ('../');
  }
  onError ($event) {
    this.error = $event;
    this.hideOtherFields = false;
  }
  hasTheme() {
    return this.cf.theme !== 'none';
  }
  toggleAdminLogin() {
    this.externalUrl.goToUrl ('./?admin=1')
  }
  getAdminLogin() {
    return this._adminLogin;
  }
  showSSO() {
    return  (this.showSaml() || this.showOidc());
  }
  showSaml() {
    return this.cf.saml;
  }
  showOidc() {
    return this.cf.oidc;
  }
  showOther() {
   
    return this.queryParams.admin || (!this.hideOtherFields && (this.showSSO() || this.showGuest() || this.showAdminLogin()));
  }
  showGuest() {
    //TODO SHOW GUEST
    return false ; //!this.cf.hideGuestLogin;
  }
  showAdminLogin() {
    return !this.cf.hideAdminButton || this.queryParams.admin;
  }
  getDisclaimer() {
    return this.cf.disclaimer;
  }
  goToSaml() {
    this.externalUrl.goToUrl("../saml")
  }
  goToOidc() {
    this.externalUrl.goToUrl("../oidc")
  }
  goToGuest() {

  }
  sshLoggingIn($event) {
    this.hideOtherFields = true;
  }
  loginContainerClass() {
     return { 
      "order-last": this.cf.themePosition === "right",
      "border-0": true,
    };
  }
  showExtensionBanner() {
    return 0;
    return !this.cf.hideExtensionBanner && this.showBanner;
  }
}
