import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";
import { NgModule, ViewContainerRef } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { ConfigModule } from '@Common/config';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SshModule } from "@Common/ssh/ssh";
import { UtilModule } from "@Common/util";
import { PageModule, UiModule } from "@Common/page"
import { AuthAdminModule } from './auth-admin/auth-admin.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    NgbModule,
    SshModule,
    ConfigModule,
    PageModule,
    UtilModule,
    UiModule,
    AuthAdminModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
}

