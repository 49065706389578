<ngb-alert *ngIf="showExtensionBanner()" id="ssh-extension-banner" [dismissible]="true" type="warning" class="fixed-top">
    <strong>Do you want to log in using SSH Keys?</strong> Download the extension to connect to your local SSH Agent!
</ngb-alert>
<div *ngIf="!cookies()" class="rounded-0  card card-body" id="no-cookies">
    You must enable cookies on your system to continue
</div>

<div *ngIf="cookies() && loaded" class="container p-0 rounded position-relative top-container">
    <div class="container"> 
        <div id="ssh-login-container" class="row m-auto login-container">
            <div *ngIf="hasTheme() && (!cf.themePosition || cf.themePosition === 'left' || cf.themePosition === 'right')" 
            class="col-lg-5 p-0" 
            [ngClass]='loginContainerClass()'
            id="ssh-login-header">
                <fx-ssh-header></fx-ssh-header>
            </div>  
            <div class="col-lg p-0 m-0" id="ssh-login-form" [ngClass]="cf.themePosition === 'top' ? 'p-0' : ''" style="overflow:auto">
                <div *ngIf="hasTheme() && cf.themePosition === 'top'" >
                    <fx-ssh-header></fx-ssh-header>
                </div>

                
            
                <div class="bg-white p-3 h-100 d-flex flex-column justify-content-around">
                    <error-alert *ngIf="error && error.message" [error]="error"></error-alert>
                    <div class="w-100 " *ngIf="!cf.disable">
                        <div class="container ">
                            <div class="mt-3 d-flex flex-fill align-items-center" >
                                <fx-ssh *ngIf="!getAdminLogin()" class="w-100" 
                                    [exec]="exec ()"
                                    (intermediateStage)="sshLoggingIn($event)" 
                                    (success)="onSuccess ($event)"
                                    (cancel)="onCancel ($event)" 
                                    (error)="onError ($event)"
                                    [adminLogin]="getAdminLogin()"
                                >
                                    </fx-ssh>
                            </div>
                        </div>
                    </div>
                    <fx-auth-admin *ngIf="getAdminLogin()" class="w-100" ></fx-auth-admin>
                    <div  *ngIf="showOther()" id="ssh-login-sso">
                        <div class="d-flex mt-5 mb-4" *ngIf="!cf.disable && !getAdminLogin()">
                            <span class="w-100 flex-grow-1">
                                <hr/>
                            </span>
                            
                            <div class="text-muted text-center w-100">Other Login Services</div>
                            <span class="w-100 flex-grow-1">
                                <hr />
                            </span>
                            
                        </div>
                        <div class="d-flex justify-content-center " *ngIf="!getAdminLogin()">
                            <div class="d-grid gap-2 w-100">
                                <button *ngIf="showSaml()" class="btn btn-outline-primary mx-3" (click)="goToSaml()">SAML</button>
        
                                <button *ngIf="showOidc()" class="btn btn-outline-primary mx-3" (click)="goToOidc()">OpenId Connect</button>
                                
                                <button *ngIf="showAdminLogin()"  [ngClass]="{ 
                                    'btn-outline-primary': !getAdminLogin(),
                                    'btn-primary': getAdminLogin()
                                }" class="btn  btn-block mx-3" (click)="toggleAdminLogin()"
                                id="ssh-login-admin-button"
                                >Admin Login</button>
                            </div>
                           
                        </div>
                    </div>
               
                    <div *ngIf="showOther() && 0"  [ngClass]="hideOtherFields ? 'invisible' : 'visible' " class="d-flex mt-5 mb-4 mx-3" id="ssh-login-other">
                        <span class="w-100 flex-grow-1">
                            <hr/>
                        </span>
                        
                        <div class="text-muted text-center w-100">Other</div>
                        <span class="w-100 flex-grow-1">
                            <hr />
                        </span>
                        
                    </div>
                    <div  *ngIf="showOther()"  [ngClass]=" hideOtherFields ? 'invisible' : 'visible' "  class="d-flex justify-content-around">
                        
                        <button *ngIf="showGuest()" 
                        id="ssh-login-guest-button"
                        class="btn btn-outline-primary">Guest Login</button>
        
                        
                    </div>
                    <div class="mt-4 d-flex justify-content-center" id="ssh-login-disclaimer"> 
                        <small [innerHTML]="getDisclaimer()"></small>
                    </div>
                    <div class="mt-5 mb-2 mx-3" id="ssh-footer">
                        <fx-ssh-footer></fx-ssh-footer>
                    </div>
                </div>
           
            <div *ngIf="hasTheme() && cf.themePosition === 'bottom'" >
                <fx-ssh-header></fx-ssh-header>
            </div>
            
        </div>
      
                    
    </div>
   
</div>
<div id="fast@starnet.com-not-installed"></div>
