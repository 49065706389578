<div [formGroup]="form" (keydown.enter)="onSubmit ()">
  <ngb-alert *ngIf="getError()" [ngStyle]="{ 'visibility': getError() ? 'visible' : 'hidden' }"  [type]="'danger'" (click)="hideError()">{{ getError() }}</ngb-alert>
  <div class="mb-3 mb-2 pb-3">
    <div class="input-group mb-2">
      <span class="input-group-text icon-prefix">
          <fa-icon icon="key"></fa-icon>
      </span>
      <div class="form-floating">
          <input [type]="inputType()" 
          form="form"  
          id="answer"
          placeholder="**********" 
          #pw formControlName="password" id="answer" class="form-control "
          />
          
          <label  class="mb-0" for="answer">{{ prompt }}</label>
      </div>
      <button (click)="toggleInputType()" class='btn btn-link border'>
          <fa-icon icon="eye" ></fa-icon>
      </button>   
  </div>
  
  <footer-submit-cancel #foot (submit)="onSubmit ()"  (cancel)="onCancel ()"></footer-submit-cancel>
</div>