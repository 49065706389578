import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubmitCancelComponent } from "@Common/events";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { KeyStoreService } from "../key-store.service";

@Component({
  selector: 'fx-public-key',
  templateUrl: './public-key.component.html',
  styleUrls: ['./public-key.component.css']
})
export class PublicKeyComponent extends SubmitCancelComponent implements OnInit {
  form: FormGroup;
  @Output () error= new EventEmitter ();
  @Input () data:any = {};
  @Input () ks: KeyStoreService;
  disabled = false;
  private i = 0;
  private MAXTRIES = 3;
  constructor(private fb: FormBuilder) {
    super ();
   }
  async reset() {
    try {
      await this.ks.reset();
    } catch(e) {
      console.log(e);
    }
  }
  update () {
    this.ngOnInit ();
  }
  async ngOnInit() {
    this.form = this.fb.group ({
      authtoken: ['', Validators.required],
      publicKey: ['', Validators.required]
    });
    this.form.patchValue ({ authtoken: this.data.authtoken });
    this.disabled = false;

    
   
   

   do {
      try {
        this.tryKey ();
        return;
      } catch(e) {
        console.log(e);
        this.nextKey();
      }
     
    }  while(this.ks.current());
   
  }
  onSubmit () {
    this.disabled = false;
    this.submit.emit (this.form.value);
  }
  onCancel () {
    this.disabled = false;
    this.cancel.emit ();
  }
  decrypt () {
    return this.ks.encrypted();
  }
  key () {
    return this.ks.current ();
  }
  decryptKey (pk) {
    if (!pk) return this.nextKey ();
    this.ks.setPk (pk);
    this.ks.getPk().then(p => {
      this.form.patchValue ({ publicKey: p.getPublicKeyBlob() });
      this.onSubmit ();
    });
   
  }
  disableDecrypt () {
    return this.disabled;
  }
  onDecryptError (e) {
    this.disabled = false;
    this.i++;
    this.error.emit (e);
    if (this.i < this.MAXTRIES) return;
    this.nextKey ();    
  }
  nextKey () {
    this.ks.next ();
    this.i = 0;
    try {
      this.tryKey ();
    } catch(e) {
      return;
    }
    
  }
  tryKey () {
    let c = this.ks.current ();
    if (!c) { //no more keys
      this.form.patchValue ({ publicKey: '' });
      return this.onSubmit ();
    }
    if (this.ks.encrypted()) return;
    let pk = this.ks.decrypt ();

    return this.decryptKey (pk);
  }

}
