import { Component, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthAdmin } from "@Common/api/auth/admin";

@Component({
  selector: 'fx-auth-admin',
  templateUrl: './auth-admin.component.html',
  styleUrls: ['./auth-admin.component.css']
})
export class AuthAdminComponent {
  error
  _adminLogin;
  _inputType = 'password';
  form: FormGroup;
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
 
  }
  ngOnInit() {
    this.form = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }
  toggleAdminLogin() {
    this._adminLogin = !this._adminLogin;
  }
  toggleInputType() {
    this._inputType = this._inputType === 'password' ? 'text' : 'password';
    this.cdr.detectChanges();
  }
  inputType() {
    return this._inputType;
  }
  async onSubmit() {
    try {
      if(!this.form.value['password']) {
        throw new Error('Password is required');
      }
      let o = await AuthAdmin.admin(this.form.value);
      window.localStorage.csrf = o.csrf;
      window.location.assign('..');
    } catch(e) {
      this.error = e;
      this.form.patchValue({ password: "" });
    }
  }
}
