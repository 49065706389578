import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AuthAdminComponent } from './auth-admin/auth-admin.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {  faTimes, faPlus, faEye, faUserTie, faKey } from '@fortawesome/free-solid-svg-icons';
import { PageModule } from '@Common/page';


@NgModule({
  declarations: [
    AuthAdminComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PageModule
  ],
  exports: [
    AuthAdminComponent
  ]
})
export class AuthAdminModule { 
  constructor (library: FaIconLibrary) {
    library.addIcons (faTimes);
    library.addIcons (faPlus);
    library.addIcons(faEye);
    library.addIcons(faUserTie);
    library.addIcons(faKey);
  }
}
