import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges,
  ViewEncapsulation, ViewChildren, QueryList, ComponentRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { GridCellComponent } from './cell/grid-cell.component';
import { ContainerDirective } from './container.directive';
import { ComponentLoaderService } from '@Common/component-loader';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit {
  @Input() showHeader = false;
  @Output () selected = new EventEmitter ();
  @Output () rowDblClicked = new EventEmitter ();
  @Output() rowHover = new EventEmitter();
  @Input () gridOptions = {} as any;
  constructor(private cl: ComponentLoaderService,
    private cdr: ChangeDetectorRef
    ) { }
  mySelections = [];
  subscribers = []
  @ViewChildren(ContainerDirective) componentBlocks: QueryList<ComponentRef<GridCellComponent>>;
  ngOnInit() {

  }
  ngAfterViewInit() {
  
    this.componentBlocks.changes.subscribe((d: any) => {
      this.resubscribe(d);
    });

  }
  ngOnChanges(changes: SimpleChanges) {
    this.cdr.detectChanges();
  }
  resubscribe(d) {
    this.subscribers.forEach(c => c.unsubscribe());
    this.subscribers = [];

    d.forEach(c => {
      c.viewContainerRef.clear();
      let ref = this.cl.load(c.component, c.viewContainerRef);
 
      ref.instance.params = c.params;
      ref.instance.col = c.col;
      ref.instance.data = c.data;
      
      this.subscribers.push( this.selected.subscribe(next => {
        ref.instance.isSelected = next.find(c => c[this.dataKey()] === ref.instance.params[this.dataKey()]);
        ref.instance.multiselect = next.length > 1;
        this.cdr.detectChanges();
      }));

      this.subscribers.push(
        this.rowHover.subscribe(next => {
          ref.instance.hovering = next.hovering && (next.row[this.dataKey()] == ref.instance.params[this.dataKey()]);
          this.cdr.detectChanges();
        })
      );

      this.cdr.detectChanges();
    });
  }
  onRowSelect($event) {
    if(this.gridOptions.rowSelection === 'single') {
      this.mySelections = $event?.data ? [$event.data]: [$event];
      this.selected.emit(this.mySelections); 
      return;
    }
    let ids = this.gridOptions.data.map(c => c[this.dataKey()]);
    this.mySelections = this.mySelections.filter(c =>  ids.indexOf(c[this.dataKey()]) >= 0);
    this.selected.emit(this.mySelections);
  }
  onRowUnselect($event) {
    if(this.gridOptions.rowSelection === 'single') {
      this.mySelections = [];
      this.selected.emit(this.mySelections); 
      return;
    }
    if(this.gridOptions.unselectFilter && this.gridOptions.unselectFilter($event)) {
      return;
    }
    let ids = this.gridOptions.data.map(c => c[this.dataKey()]);
    this.mySelections = this.mySelections.filter(c =>  ids.indexOf(c[this.dataKey()]) >= 0);
    this.selected.emit(this.mySelections);
  }
  styleClass() {
    return 'p-datatable-sm p-datatable-striped ' + (this.gridOptions.styleClass ? this.gridOptions.styleClass : '');  
  }
  onClick(rowData) {
  
  }
  onDoubleClick(rowData) {
   
    if(!this.gridOptions.onRowDoubleClicked)
      return;
   
    this.gridOptions.onRowDoubleClicked(rowData);
  }
  getField(rowData, field) {
    if(!field) {
      return (typeof rowData === 'string' ) ? rowData : undefined;
    }
    let f = field.split('.');
    let d = rowData;
    f.forEach(c => {
      if(typeof d === 'object')
        d = d[c];
    });
    return d;
  }
  dataKey() {
    return this.gridOptions.dataKey || 'id';
  }
  hover(hovering, rowData) {
    this.rowHover.emit({
      hovering: hovering,
      row: rowData
    })
  }

  toggleOne(rowData) {
    //maybe
    this.onClick(rowData);
  }
  selectAll($event) {
    this.selected.emit(this.mySelections); 
  }
  displayedColumns() {
    return this.gridOptions?.columnDefs?.map(c => c.headerName)

  }
  customSort($event: SortEvent) {
    $event.data.sort((data1, data2) => {
      if(this.gridOptions.customSort)
        return this.gridOptions.customSort($event);
      
      let value1 = this.getField(data1, $event.field);
      let value2 = this.getField(data2, $event.field);
      let result = null;
 

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else {
        let f = this.gridOptions?.columnDefs?.find(c => c.field === $event.field);
        if(f && f.comparator) {
          result = f.comparator(value1, value2, data1, data2 )
        }  else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      }
     
      return ($event.order * result);
    });
  }
  getSortField(col) {
    return col.colId || col.field;
  }
  handleCloseClicked(rowData) {
    if(this.gridOptions.rowSelection === 'single') {
      this.mySelections = rowData; 
    } else {
      this.mySelections = this.mySelections.filter(c => c[this.dataKey()] !== rowData[this.dataKey()]);
    }
   
    this.gridOptions.data = this.gridOptions.data?.filter(c => c[this.dataKey()] !== rowData[this.dataKey()]);

    if(this.gridOptions.onCloseRowClicked) {
      this.gridOptions.onCloseRowClicked(rowData);
    }
    this.selected.emit(this.mySelections);
  }
  isSelected(rowData) {
    return this.mySelections.length && (this.mySelections.findIndex(c => c[this.dataKey()] === rowData[this.dataKey()] ) >= 0);
  }
  doFilter(dt, $event) {
    return dt.filterGlobal($event.target?.value, 'contains');
  }
  doCellRenderer(data, htm) {
    Promise.resolve(data).then(d => {
      htm = d;
    });
    return htm;
  }
}
