<!-- <div class="mat-elevation-z8">
    <table mat-table [dataSource]="gridOptions.data">
     <ng-container *ngFor="let col of gridOptions.columnDefs">
         <th mat-header-cell *matHeaderCellDef>{{ col.headerName }}</th>
         <td mat-cell *matCellDef="let element">
             <span [innerHTML]="col.cellRenderer ? col.cellRenderer(element) : getField(element, col.field)"></span>
         </td>
     </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns();"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[50, 100, 200]"
                   showFirstLastButtons 
                   aria-label="Select page ">
    </mat-paginator>
  </div>
 -->

<p-table [columns]="gridOptions.columnDefs" #dt

[showCurrentPageReport]="true"
currentPageReportTemplate="{first} to {last} of {totalRecords}"
[paginator]="gridOptions.pagination && (gridOptions?.data?.length >= 50) " [rows]="50"
[rowsPerPageOptions]="[50, 100, 200]"

[rowHover]="true"
[autoLayout]="true" 
[value]="gridOptions.data" 
responsiveLayout="scroll"
[styleClass]="styleClass()" 

[dataKey]="dataKey()"
(onHeaderCheckboxToggle)="selectAll($event)"
[(selection)]="mySelections"
(onRowSelect)="onRowSelect($event)" 
(onRowUnselect)="onRowUnselect($event)"
[selectionMode]="gridOptions.rowSelection || 'single'"
(sortFunction)="customSort($event)" [customSort]="true"

>
    <ng-template *ngIf="showHeader" pTemplate="caption">
     
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="gridOptions.columnDefs.length + 10"
            style="text-align:center" 
            [innerHTML]="gridOptions.overlayNoRowsTemplate || 'No Data'"></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody" >
        <tr>
            <td [attr.colspan]="gridOptions.columnDefs.length + 10"
            [innerHTML]="gridOptions.overlayLoadingTemplate || 'Loading'"
            >

            </td>
        </tr>
    </ng-template>
    <ng-template  pTemplate="header" let-columns>
        <tr >
            <th *ngIf="gridOptions.columnDefs[0].checkboxSelection" style="width:1em">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th> 
            <th *ngFor="let col of columns" [pSortableColumn]="getSortField(col)">
                {{col.headerName}} <p-sortIcon *ngIf="(col.sortable !== false) && getSortField(col)" [field]="getSortField(col)"></p-sortIcon>
            </th>
            <th *ngIf="gridOptions.deleteRowButton" style="width:1em">
               
            </th> 
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr
        (dblclick)="onDoubleClick(rowData)"
        [pSelectableRow]="rowData"
        (mouseenter)="hover(true , rowData)"
        (mouseleave)="hover(false, rowData)" 
        >
            <td *ngIf="gridOptions.columnDefs?.length &&  gridOptions.columnDefs[0].checkboxSelection">
                <p-tableCheckbox   [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngClass]="col.className"> 
                <div *ngIf="col.cellButton; else elseCell">
                    <button class="btn btn-link p-0" (click)="col.cellButton($event,rowData)">{{ col.cellRenderer(rowData) }}</button>
                </div>
                <ng-template #elseCell>
                   
                    <div *ngIf="col.cellRenderer; else elseBlock" [innerHTML]="col.cellRenderer(rowData) "></div>
                    <ng-template #elseBlock>
                        <span *ngIf="!col.componentRef; else componentBlock"  >
                            {{ getField(rowData, col.field) }}
                        </span>
                        <ng-template #componentBlock>
                            <div fxContainer [component]="col.componentRef" [params]="rowData" [col]="col" [data]="col.data"></div>
                        </ng-template>
                    </ng-template>
                </ng-template>
               
            </td>
            <th *ngIf="gridOptions.deleteRowButton" style="width:1em">
                <button *ngIf="isSelected(rowData)"
                pButton
                 class="p-button-outlined p-button-secondary border-0" 
                 icon="pi pi-times" (click)="handleCloseClicked(rowData)"></button>
            </th> 
        </tr>
    </ng-template>
</p-table>