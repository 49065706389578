import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '@Common/config';

@Component({
  selector: 'fx-ssh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  src = "../../theme/logo";
  tag = "Fast and Secure Connections to your Linux desktop";
  myConfig = {} as any;
  config$;
  constructor(private config: ConfigService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.config$ = this.config.subscribe (d => { 
      this.myConfig = d;
      this.cdr.detectChanges();
     });
  }
  ngOnDestroy () {
    this.config$.unsubscribe ();
  }
  img () {
    return this.src
  }
  text () {
    return this.myConfig.tagline;
  }
  theme () {
    return this.myConfig.theme;
  }
  navBarClass() {
    switch(this.myConfig.theme) {
      case 'none':
        return 'd-none';
      case 'light':
          return "w-100  h-100 navbar-light bg-light border-0";
      case 'transparent':
          return "w-100  h-100 bg-transparent border-0";
      case 'dark':
      default:
          return "w-100 h-100 navbar-dark bg-dark border-0";

    }
  }
  headerClass() {
    return ['top', 'bottom'].indexOf(this.myConfig.themePosition) >= 0 ? 'w-100 h-75 d-flex justify-content-center' : 'w-100 h-75 d-flex justify-content-center';
  }
  imgClass() {
    return ['top', 'bottom'].indexOf(this.myConfig.themePosition) >= 0 ?  'topImage w-25 p-3' : "w-100 p-3 m-auto";
  }
  taglineClass() {
    return ['top', 'bottom'].indexOf(this.myConfig.themePosition) >= 0 ? "h-25 w-100 d-flex justify-content-center container" :  "h-25 w-100 d-flex align-items-end container";
  }

}
