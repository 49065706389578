import { Injectable } from '@angular/core';
import KeyStore  from "../key-store";
import { KeyStore as KeyStoreExtension } from "../key-store-extension";
import  PrivateKey  from "../private-key";

@Injectable({
  providedIn: 'root'
})
export class KeyStoreService {
  private ks;
  private keys = [];
  private i = 0;
  private agent;
  constructor() { 
  } 
  usePK () {
    return this.keys.length > 0;
  }
  async reset () {
    let ext = document.getElementById('fastx@starnet.com');

    this.agent = Boolean(ext?.dataset?.enabled);

    this.ks = this.agent? new KeyStoreExtension() : new KeyStore ();
  
    this.keys = await this.ks.read (); 
    this.i = 0;
    return this.keys;

  }
  setPk (pk) {
    let k = this.current ();
    if (!k) return;
    k.privateKey = pk;
    this.keys [this.i] = k;
  }
  async getPk () {
    let c = this.current ();
    return c ? c.privateKey : null;
  }
  decrypt (passphrase = '') {
    let c = this.current ();
  
    let pk = PrivateKey.factory(c.key, this.agent);
   
    if(pk.isEncrypted()) {
      return pk.decrypt(passphrase) ? pk : null;
    }
    
    return pk.decrypt() ? pk : null;
  }
  encrypted () {
    let c = this.current ();
    if(!c)
      return;

    let pk = PrivateKey.factory(c.key, this.agent);
    return pk.isEncrypted();
  }
  current () {
    return this.keys.length <= this.i ? null : this.keys [this.i];
  }
  next () {
    return this.keys.length <= this.i ? null : this.keys [++this.i];
  }

}
