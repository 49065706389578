<div [formGroup]="form" (keydown.enter)="onSubmit ()">
  <div class="mb-3 mb-2">
    <label for="decrypt" >{{ prompt }} <strong>{{ name () }}</strong></label>
    <div class="input-group input-group mb-3">
      <input [type]="inputType()" #pw formControlName="passphrase" id="decrypt" class="form-control " />
        <button (click)="toggleInputType()" class='btn btn-link border'>
          <fa-icon icon="eye" ></fa-icon>
      </button>
    </div>
  </div>
  <footer-submit-cancel #foot (submit)="onSubmit ()"  (cancel)="onCancel ()"></footer-submit-cancel>
</div>