import { Directive, ViewContainerRef, Input, ComponentRef } from '@angular/core';
import { GridCellComponent } from './cell/grid-cell.component';
@Directive({
  selector: '[fxContainer]',
})
export class ContainerDirective {
@Input() params = {} as any;
@Input() col = {} as any;
@Input() component: ComponentRef<GridCellComponent>;
@Input() hovering = false;
@Input() data = {} as any;
  constructor(public viewContainerRef: ViewContainerRef) { }
}