
<div [ngSwitch]="stage ()"> 
    <fx-login *ngSwitchCase="'login'" 
      (submit)="handle ($event)" 
      [disable]="_disable"
      [error]="err"
      [adminLogin]="adminLogin"
    ></fx-login>
    <fx-ssh-banner #banner *ngSwitchCase="'banner'" [data]="data"
    (submit)="handle ($event)"
    (cancel)="onCancel ($event)"
    ></fx-ssh-banner> 
    <fx-keyboard-interactive #kb *ngSwitchCase="'keyboard-interactive'" [data]="data"
    (submit)="handle ($event)"
    (cancel)="onCancel ($event)"
    ></fx-keyboard-interactive>
    <fx-password #pw *ngSwitchCase="'password'" [data]="data"
      (submit)="handle ($event)"
      (cancel)="onCancel ($event)"
     ></fx-password>
     <fx-server #server *ngSwitchCase="'server'" [data]="data"
     (submit)="handle ($event)"
     (cancel)="onCancel ($event)"
     [data]="data"
     ></fx-server>
    <fx-public-key #pubKey *ngSwitchCase="'public-key'" [data]="data"
     (submit)="handle ($event)"
      (cancel)="onCancel ($event)"
      (error)="onError ($event)"
      [ks]="ks"
     ></fx-public-key>
     <fx-ssh-sign *ngSwitchCase="'sign'" (cancel)="onCancel ($event)"></fx-ssh-sign>
</div>