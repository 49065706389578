<div class="w-100" (keydown.enter)="onSubmit ()">
    <div class="mb-2 d-flex">
        <div class="w-100">
            <div [ngClass]="float()">
                <button class="btn  btn-primary" (click)="open ()">
                    <fa-icon icon="plus"></fa-icon>
                </button>
            </div>
        </div>
      
    </div>

    <div class="mb-2">     
        <ngb-alert type="danger" *ngIf="error">{{ error.message }}</ngb-alert>
        <app-grid (selected)="onSelectionChanged($event)" [gridOptions]="gridOptions"  class="grid "></app-grid> 
        <small>* RSA keys supported</small>
    </div>
    <div class="mb-2" *ngIf="!hideSaveButton">
      <footer-close #close (submit)="onSubmit ()"></footer-close>
    </div>
</div>
