<div [formGroup]="getForm()" (keydown.enter)="onSubmit ()">
    <ngb-alert [ngStyle]="{ 'visibility': getError() ? 'visible' : 'hidden' }" [type]="'danger'" (click)="hideError()">{{ getError() }}</ngb-alert>
    <div class="mb-3 mb-2">
      <label for="server" >Server</label>
      <select class='form-control ' formControlName="serverId" >
        <option *ngIf="data.automatic" [value]="''">Automatic</option>
        <option *ngFor="let s of data.servers" [value]="s.id">
            {{ s.hostname }}
        </option>
    </select>
    </div>
    <footer-submit-cancel #foot (submit)="onSubmit ()"  (cancel)="onCancel ()"></footer-submit-cancel>
  </div>
