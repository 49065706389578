
<div *ngIf="showManage ()"  class="w-100">
    <fx-ssh-manage (submit)="onManageDone ()" class=""></fx-ssh-manage>
</div>
<div  *ngIf="!showManage ()" [formGroup]="form" (keydown.enter)="onSubmit ()" >

    <div class="">
        <div class="mb-1">
            <div class="input-group mb-3">
                <span class="input-group-text icon-prefix">
                    <fa-icon icon="user-tie"></fa-icon>
                </span>
                <div class="form-floating">
                    <input type="text"  
                    class="form-control " 
                    required='required'  
                    form="form" 
                    formControlName="username"
                    placeholder="user"
                    id="userInput"
                    #username 
                />
                <label for="userInput">Username</label>
            </div>
        </div>
     
        <div class="mb-1" *ngIf="showPassword ()" id="password-input">
            <div class="input-group input-group">
                <span class="input-group-text icon-prefix">
                    <fa-icon icon="key"></fa-icon>
                </span>
                <div class="form-floating">
                    <input [type]="inputType()" 
                        form="form"  
                        formControlName="password" 
                        id="answer"
                        placeholder="**********" 
                        class="form-control " />
                    <label  class="mb-0" for="answer">Password</label>
                </div>
                <button (click)="toggleInputType()" class='btn btn-link border'>
                    <fa-icon icon="eye" ></fa-icon>
                </button>
            </div>
        </div>
            
       
        <div *ngIf="showPublicKey ()" id="private-key-input">
           <input type="hidden" form="form" [value]="true" formControlName="usePK" /> 
            <div class="float-end">
                <button class="btn btn-link p-0" (click)="manageKeys ()">Manage private keys</button>
            </div>
        </div>
    </div>
    <div class="mb-4">
        <div class="mt-5 d-grid " role="group" aria-label="Login">
            <button  class="btn btn-primary"
                [disabled]="disable"
                form="form" 
                    (click)="onSubmit ()">SSH Login</button>
        </div>
    </div>
</div>